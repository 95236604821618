import { FC, useMemo, useRef } from 'react';
import Table, { ITableProps } from '../../../../components/Table';
import Accordion from '../../../../components/Accordion';
import s from '../../style/LocationProfilePage.module.scss';
import { PostCensusTable } from '../../../../API';

const CensusTable: FC<ITableProps> = ({ formData, setIsLoading }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Tract Name',
        accessor: 'tractName',
      },
      {
        Header: 'State',
        accessor: 'stateName',
      },
      {
        Header: 'County',
        accessor: 'countyName',
      },
      {
        Header: 'IA Qualified',
        accessor: 'iaQualified',
      },
      {
        Header: 'Poverty Rate',
        accessor: 'povertyRate',
      },
      {
        Header: 'Median Household Income',
        accessor: 'medianIncome',
      },
      {
        Header: 'Unemployment Rate',
        accessor: 'unemploymentRate',
      },
      {
        Header: 'Unemployment Rate Qualified',
        accessor: 'unemploymentRateQualified',
      },
      {
        Header: 'Empowerment Zone',
        accessor: 'empowermentZone',
      },
      {
        Header: 'Population Loss Metro',
        accessor: 'populationLossMetro',
      },
      {
        Header: 'Metro Designation',
        accessor: 'metroDesignation',
      },
      {
        Header: 'Population',
        accessor: 'acsPopulation',
      },
    ],
    []
  );
  const wrapperElement = useRef<HTMLDivElement>(null);

  return (
    <div className={`${s.block} ${s.block__table}`} ref={wrapperElement}>
      <Accordion
        title="CDFI investment area (IA) data"
        childrenExtraHeight="1900"
      >
        <div className={`${s.block__table__body}`}>
          <Table
            focusElement={wrapperElement}
            formData={formData}
            setIsLoading={setIsLoading}
            fetchFunction={PostCensusTable}
            columns={columns as any}
          />
        </div>
      </Accordion>
    </div>
  );
};

export default CensusTable;
