/* eslint-disable no-constant-condition */
import { FC, useEffect, useState } from 'react';
import { useQueries } from 'react-query';
import { useLocation } from 'react-router-dom';
import Spiner from '../../components/Spiner';
import Page404 from '../Page404/Page404';
import {
  IFormData,
  PostDemographicData,
  PostIncomeDetailsData,
  PostProfileData,
  PostLocationDetails,
  PostBusinessWorkedFor,
  PostVeteranStatus,
  PostOccupancyData,
  GetLocationProfileData,
} from '../../API';
import {
  IDemographic,
  IEducationalAttainments,
  IIncomeDetails,
  IProfileData,
  ILocationDetails,
  IVeteranData,
  IBusinessesWorkedFor,
  IUnitsData,
} from '../../models';
import Breadcrumbs from '../../components/Breadcrumbs';
import ProgressBar from '../../components/ProgressBar';
import DownloadLink from '../../components/DownloadLink';
import CuInfoBlock from './widgets/CuInfoBlock';
import AgeChart from './widgets/AgeChart';
import RaceChart from './widgets/RaceChart';
import LanguageChart from './widgets/LanguageChart';
import MedianIncomeBlock from './widgets/MedianIncomeBlock';
import GenderChart from './widgets/GenderChart';
import EducationAttainments from './widgets/EducationAttainments';
import FinancialInstitutions from './widgets/FinancialInstitutions';
import RelationshipsTable from './widgets/RelationshipsTable';
import LIDPopulation from './widgets/LIDPopulation';
import PopulationTable from './widgets/PopulationTable';
import CensusTable from './widgets/CensusTable';
import AboutCU from './widgets/AboutCU';
import BusinessWorkedBlock from './widgets/BusinessWorkedBlock';
import VeteranStatus from './widgets/VeteranStatus';
import OccupancyChart from './widgets/OccupancyChart';
import csvFile from '../assets/CSV_File.svg';
import s from './style/LocationProfilePage.module.scss';

const LocationProfilePage: FC = () => {
  const { search } = useLocation();
  const [tableLoadingStatus, setTableLoadingStatus] = useState<{
    count: number;
    length: number;
  }>({ count: 0, length: 0 });
  const [formData, setFormData] = useState<IFormData>({
    idEntities: [],
    mapMode: 2,
    benchmarkTractIds: [],
  });
  const [isEmptyPage, setIsEmptyPage] = useState<boolean>(false);
  const [isLidTableLoading, setIsLidTableLoading] = useState<boolean>(false);
  const [isPopulationTableLoading, setPopulationTableLoading] =
    useState<boolean>(false);
  const [isFinancialInstitutionsLoading, setFinancialInstitutionsLoading] =
    useState<boolean>(false);
  const [isRelationshipsTableLoading, setIsRelationshipsTableLoading] =
    useState<boolean>(false);
  const [isCensusTableLoading, setCensusTableLoading] =
    useState<boolean>(false);
  const [isFirstPageLoading, setIsFirstPageLoading] = useState<boolean>(true);

  const [loadingItemsPercent, setLoadingItemsPercent] = useState<number>(0);
  const [guid] = useState<string | null>(
    new URLSearchParams(search).get('guid')
  );
  const [locationId] = useState<string | null>(
    new URLSearchParams(search).get('locationId')
  );

  const formDataResults = useQueries(
    guid || locationId
      ? ([GetLocationProfileData({ guid, locationId })] as any)
      : []
  );

  useEffect(() => {
    if (!(guid || locationId) || formDataResults[0].isLoading) return;
    const localFormData = formDataResults[0]?.data as IFormData;
    setIsEmptyPage(
      !(localFormData?.idEntities || localFormData?.benchmarkTractIds)
    );
    setFormData(formDataResults[0].data as IFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDataResults]);

  const results = useQueries(
    formData?.idEntities?.length < 1 && formData.benchmarkTractIds.length < 1
      ? []
      : ([
          PostDemographicData({ formData }),
          PostIncomeDetailsData({ formData }),
          PostLocationDetails({ formData }),
          PostProfileData({ formData }),
          PostBusinessWorkedFor({ formData }),
          PostVeteranStatus({ formData }),
          PostOccupancyData({ formData }),
        ] as any)
  );

  useEffect(() => {
    setTableLoadingStatus({
      count: [
        isCensusTableLoading,
        isRelationshipsTableLoading,
        isFinancialInstitutionsLoading,
        isPopulationTableLoading,
        isLidTableLoading,
      ].filter((item) => item === false).length,
      length:
        [
          isCensusTableLoading,
          isRelationshipsTableLoading,
          isFinancialInstitutionsLoading,
          isPopulationTableLoading,
          isLidTableLoading,
        ].length + results.length,
    });
  }, [
    isCensusTableLoading,
    isRelationshipsTableLoading,
    isFinancialInstitutionsLoading,
    isPopulationTableLoading,
    isLidTableLoading,
  ]);

  useEffect(() => {
    if (!isFirstPageLoading) {
      return;
    }
    const totalLoadingCount =
      results.filter((obj) => obj.isLoading === false).length +
      tableLoadingStatus.count;

    const currentPercent =
      (100 * totalLoadingCount) / tableLoadingStatus.length;
    if (Number.isNaN(currentPercent) || tableLoadingStatus.length !== 12) {
      return;
    }
    setLoadingItemsPercent(currentPercent);
    setIsFirstPageLoading(tableLoadingStatus.length !== totalLoadingCount);
  }, [tableLoadingStatus, results]);

  const DemographicData = results[0]?.data as IDemographic;
  const IncomeData = results[1]?.data as IIncomeDetails;
  const LocationData = results[2]?.data as ILocationDetails;
  const ProfileData = results[3]?.data as IProfileData;
  const BusinessWorkedForData = results[4]?.data as IBusinessesWorkedFor;
  const VeteranData = results[5]?.data as IVeteranData;
  const OccupancyData = results[6]?.data as IUnitsData;

  let BusinessWorkedData = [
    { name: 'Public administration', value: 0 },
    { name: 'Construction', value: 0 },
    { name: 'Manufacturing', value: 0 },
    { name: 'Wholesale trade', value: 0 },
    { name: 'Retail trade', value: 0 },
    { name: 'Transportation, warehousing and utilities', value: 0 },
    { name: 'Information', value: 0 },
    { name: 'Finance, insurance and real estate', value: 0 },
    {
      name: 'Professional, scientific, management and administrative',
      value: 0,
    },
    { name: 'Education, health care and social assistance', value: 0 },
    {
      name: 'Arts, entertainment, recreation, accommodation and food services',
      value: 0,
    },
    { name: 'Other services, except public administration', value: 0 },
    { name: 'Agriculture, forestry, fishing, hunting and mining', value: 0 },
  ];

  let occupancyData = {
    numberOfHousingUnit: '0',
    housingRental: 0,
    housingHomeowner: 0,
  };
  let veteranChartArray = [0, 0, 0, 0, 0, 0];

  let veteranInfoBlock = {
    veteranTotalFemale: 0,
    veteranTotalMale: 0,
    veteranTotal: 0,
    veteranPercent: 0,
  };
  let topCUData = {
    isContiguous: true,
    areaType: '',
    wrappedStatisticalArea: '',
    locationNames: [''],
  };
  let selectedLocations = [''];
  let aboutCUData = {
    profileSummary: {
      population: {
        totalPopulation: '',
        populationFromLowIncomeDesignatedLocations: '',
        density: '',
        withinUnderservedTract: '',
        withinUnderservedTractPercent: '',
        totalUnderservedTractPopulation: '',
        populationSource: [''],
      },
      financialInstitutionsReports: {
        cfpbUnderserved: '',
        cdfiUnderserved: '',
      },
      financialInstitutions: {
        uniqueCreditUnionsBranches: '',
        uniqueBankUnionsBranches: '',
        facilitiesTestRatio: '',
      },
      areaStats: {
        area: '',
        landMass: '',
        waterMass: '',
        waterAreaRatio: '',
      },
      benchmarkTractData: {
        selectedTracts: '',
        tractsDataShouldBeShown: true,
        facilitiesTestRatio: '',
        totalPopulation: '',
        uniqueCreditUnionBranches: '',
        uniqueBankBranches: '',
        dataIsAvailable: true,
      },
    },
  };
  let raceDataArray = [0, 0, 0, 0, 0, 0, 0, 0] as number[];
  let educationChartData = [0, 0, 0, 0, 0, 0, 0, 0] as number[];
  let educationalAttainments: IEducationalAttainments<number> = {
    populationGraduatedHighSchoolPercent: 0,
    populationWithBachelorDegreePercent: 0,
  };
  let ageChartData = [0, 0, 0, 0, 0, 0, 0] as number[];
  let languageChartArray = {
    english: 0,
    spanish: 0,
    indoEuropean: 0,
    asian: 0,
    other: 0,
  };
  let languageTableArray = {
    english: '0',
    spanish: '0',
    indoEuropean: '0',
    asian: '0',
    other: '0',
  };
  let raceTableData = {
    totalPopulation: '0',
    raceWhite: '0',
    raceBlack: '0',
    raceNative: '0',
    raceAsian: '0',
    raceIslander: '0',
    raceOther: '0',
    raceTwoOrMoreRaces: '0',
    raceHispanic: '0',
  };
  let ageTableData = {
    from0to9: '0',
    from10to19: '0',
    from20to29: '0',
    from30to39: '0',
    from40to49: '0',
    from50to59: '0',
    from60to69: '0',
    from70to79: '0',
    from80AndOver: '0',
  };
  let genderChartArray = {
    malePopulationPercent: 0,
    femalePopulationPercent: 0,
  };
  const medianIncomeData = IncomeData || {
    medianIncome: '0',
    perCapitaIncome: '0',
    personsBelowPovertyLine: 0,
  };

  if (BusinessWorkedForData) {
    BusinessWorkedData =
      BusinessWorkedForData?.businessesWorkedForData?.businessesWorkedFor;
  }

  if (ProfileData) {
    aboutCUData = ProfileData;
  }

  if (OccupancyData) {
    occupancyData = {
      numberOfHousingUnit: OccupancyData.numberOfHousingUnit,
      housingRental: OccupancyData.housingRental,
      housingHomeowner: OccupancyData.housingHomeowner,
    };
  }

  if (VeteranData) {
    const { veteranStatusData } = VeteranData;
    veteranChartArray = [
      veteranStatusData.wartimeWWII,
      veteranStatusData.wartimeKorea,
      veteranStatusData.wartimeVietnam,
      veteranStatusData.wartimeGulf1990s,
      veteranStatusData.wartimeGulf2001orLater,
    ];
    veteranInfoBlock = {
      veteranTotalFemale: veteranStatusData.veteranTotalFemale,
      veteranTotalMale: veteranStatusData.veteranTotalMale,
      veteranTotal: veteranStatusData.veteranTotal,
      veteranPercent: veteranStatusData.veteranPercent,
    };
  }

  if (LocationData) {
    const { locationTopDetails } = LocationData;
    selectedLocations = locationTopDetails?.locationsNamesSummary?.split(',');
    topCUData = {
      isContiguous: locationTopDetails?.isAreaContiguous,
      areaType: locationTopDetails?.locationsTypesSummary,
      wrappedStatisticalArea: locationTopDetails?.wrappedStatisticalArea,
      locationNames: locationTopDetails?.locationsNamesSummary?.split(','),
    };
  }

  if (DemographicData) {
    const {
      racePopulationData,
      agePopulationData,
      genderPopulation,
      languageSpokenAtHomeData,
      minimumEducationLevel,
      educationalAttainments: educationalAttainmentsData,
    } = DemographicData;
    languageChartArray = languageSpokenAtHomeData.percent;
    languageTableArray = languageSpokenAtHomeData.number;
    genderChartArray = genderPopulation;
    educationalAttainments = educationalAttainmentsData;
    educationChartData = [
      minimumEducationLevel.noDegree,
      minimumEducationLevel.highSchool,
      minimumEducationLevel.someCollege,
      minimumEducationLevel.bachelor,
      minimumEducationLevel.postGrad,
    ];
    raceTableData = racePopulationData.number;
    ageTableData = agePopulationData.number;
    raceDataArray = [
      Math.round(racePopulationData?.percent?.raceWhite * 100) / 100,
      Math.round(racePopulationData?.percent?.raceHispanic * 100) / 100,
      Math.round(racePopulationData?.percent?.raceBlack * 100) / 100,
      Math.round(racePopulationData?.percent?.raceAsian * 100) / 100,
      Math.round(racePopulationData?.percent?.raceNative * 100) / 100,
      Math.round(racePopulationData?.percent?.raceIslander * 100) / 100,
      Math.round(racePopulationData?.percent?.raceOther * 100) / 100,
      Math.round(racePopulationData?.percent?.raceTwoOrMoreRaces * 100) / 100,
    ];
    ageChartData = [
      Math.round(agePopulationData?.percent?.from0to9 * 100) / 100,
      Math.round(agePopulationData?.percent?.from10to19 * 100) / 100,
      Math.round(agePopulationData?.percent?.from20to29 * 100) / 100,
      Math.round(agePopulationData?.percent?.from30to39 * 100) / 100,
      Math.round(agePopulationData?.percent?.from40to49 * 100) / 100,
      Math.round(agePopulationData?.percent?.from50to59 * 100) / 100,
      Math.round(agePopulationData?.percent?.from60to69 * 100) / 100,
      Math.round(agePopulationData?.percent?.from70to79 * 100) / 100,
      Math.round(agePopulationData?.percent?.from80AndOver * 100) / 100,
    ];
  }

  const pageCrumbs = [
    { name: 'Home', path: '/home' },
    { name: 'Locations', path: '/locations' },
    { name: 'Selected Area', path: '/' },
  ];

  if (isEmptyPage) {
    return <Page404 />;
  }

  if (
    (formData.idEntities.length < 1 && formData.benchmarkTractIds.length < 1) ||
    results[2].isLoading
  ) {
    return (
      <div className="spinner-align">
        <Spiner />
      </div>
    );
  }
  return (
    <>
      {isFirstPageLoading && (
        <ProgressBar progressNumber={loadingItemsPercent} />
      )}
      <div className={s.header}>
        <div className={s.header__block}>
          <Breadcrumbs titleName="Selected Area" crumbs={pageCrumbs} />
        </div>
        <div className={s.header__block}>
          <DownloadLink
            btnText="Export To Excel"
            icon={csvFile}
            newTab={false}
            hrefLink="/api/locationProfile/exportToExcel"
            description="Download file"
            formData={formData}
          />
        </div>
      </div>
      <div className={s.wrapper}>
        <CuInfoBlock topData={topCUData}>
          <AboutCU
            data={aboutCUData}
            selectedLocations={selectedLocations}
            isLoading={results[3].isLoading}
          />
        </CuInfoBlock>
        <RaceChart
          chartData={raceDataArray}
          raceTableData={raceTableData}
          isLoading={results[0].isLoading}
        />
        <AgeChart
          chartData={ageChartData}
          ageTableData={ageTableData}
          isLoading={results[0].isLoading}
        />
        <LIDPopulation
          setIsLoading={setIsLidTableLoading}
          formData={formData}
        />
        <PopulationTable
          setIsLoading={setPopulationTableLoading}
          formData={formData}
        />
        <RelationshipsTable
          setIsLoading={setIsRelationshipsTableLoading}
          formData={formData}
        />
        <FinancialInstitutions
          setIsLoading={setFinancialInstitutionsLoading}
          formData={formData}
        />
        <BusinessWorkedBlock
          isLoading={results[4].isLoading}
          data={BusinessWorkedData}
        />
        <CensusTable setIsLoading={setCensusTableLoading} formData={formData} />
        <EducationAttainments
          chartData={educationChartData}
          informationData={educationalAttainments}
          isLoading={results[0].isLoading}
        />
        <MedianIncomeBlock
          data={medianIncomeData}
          isLoading={results[1].isLoading}
        />
        <OccupancyChart data={occupancyData} isLoading={results[6].isLoading} />
        <VeteranStatus
          infoData={veteranInfoBlock}
          isLoading={results[5].isLoading}
          chartData={veteranChartArray}
        />
        <div className={`${s.wrapper__block} row`}>
          <div className={`${s.wrapper__block__item} col-sm-6`}>
            <LanguageChart
              languageChartArray={languageChartArray}
              languageTableArray={languageTableArray}
              isLoading={results[0].isLoading}
            />
          </div>
          <div className={`${s.wrapper__block__item} col-sm-6`}>
            <GenderChart
              data={genderChartArray}
              isLoading={results[0].isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationProfilePage;
