/* eslint-disable max-len */
import { FC } from 'react';
import s from './style/AboutCU.module.scss';
import InfoBlock from './InfoBlock';
import { IProfileData } from '../../../../models';

type IAboutCU = {
  data: IProfileData;
  selectedLocations: string[];
  isLoading: boolean;
};

const AboutCU: FC<IAboutCU> = ({ data, selectedLocations, isLoading }) => {
  const { profileSummary } = data;
  const landMass = profileSummary?.areaStats?.landMass
    ?.replace(/[^0-9,.]/g, '')
    .replace(',', '');
  const waterMass = profileSummary?.areaStats?.waterMass
    ?.replace(/[^0-9,.]/g, '')
    .replace(',', '');
  return (
    <div className={`${s.wrapper}`}>
      <div className={`${s.wrapper__row} row`}>
        <div className={`${s.wrapper__column__left} col-sm-6`}>
          <InfoBlock
            title="CFPB"
            data={[
              {
                name: 'Underserved',
                value:
                  profileSummary.financialInstitutionsReports.cfpbUnderserved,
                valueType: isLoading ? 'loading' : 'value',
              },
            ]}
          />
          <InfoBlock
            title="Population"
            data={[
              {
                name: 'Total Population',
                value: profileSummary.population.totalPopulation,
                valueType: isLoading ? 'loading' : 'value',
              },
              {
                name: 'Population Data Source',
                value: profileSummary.population.populationSource,
                valueType: isLoading ? 'loading' : 'value',
              },
              {
                name: 'Density',
                value: profileSummary.population.density,
                valueType: isLoading ? 'loading' : 'value',
              },
              {
                name: 'Population from LID locations',
                value:
                  profileSummary.population
                    .populationFromLowIncomeDesignatedLocations,
                valueType: isLoading ? 'loading' : 'value',
              },
              {
                name: 'Within Underserved Tracts',
                value: {
                  result:
                    profileSummary.population.withinUnderservedTractPercent,
                  top: profileSummary.population.withinUnderservedTract,
                  down: profileSummary.population
                    .totalUnderservedTractPopulation,
                },
                valueType: isLoading ? 'loading' : 'formula',
              },
            ]}
          />
          <InfoBlock
            title="Depository Institutions"
            data={[
              {
                name: 'CU Branches',
                value:
                  profileSummary.financialInstitutions
                    .uniqueCreditUnionsBranches,
                valueType: isLoading ? 'loading' : 'value',
              },
              {
                name: 'Bank Branches',
                value:
                  profileSummary.financialInstitutions.uniqueBankUnionsBranches,
                valueType: isLoading ? 'loading' : 'value',
              },
              {
                name: 'Total',
                value:
                  +profileSummary.financialInstitutions.uniqueBankUnionsBranches
                    ?.replace(/[^0-9,.]/g, '')
                    .replace(',', '') +
                  +profileSummary.financialInstitutions.uniqueCreditUnionsBranches
                    ?.replace(/[^0-9,.]/g, '')
                    .replace(',', ''),
                valueType: isLoading ? 'loading' : 'value',
              },
            ]}
          />
          <InfoBlock
            title="Concentration of Facilities Test Ratio"
            data={[
              {
                name: 'Benchmark Area Ratio',
                value: {
                  result: profileSummary.benchmarkTractData.facilitiesTestRatio,
                  down: `${profileSummary.benchmarkTractData.uniqueBankBranches}
                   + ${profileSummary.benchmarkTractData.uniqueCreditUnionBranches}`,
                  top: profileSummary.benchmarkTractData.totalPopulation,
                },
                valueType: isLoading ? 'loading' : 'formula',
              },
              {
                name: 'Selected Area Ratio',
                value: {
                  result:
                    profileSummary.financialInstitutions.facilitiesTestRatio,
                  down: `${profileSummary.financialInstitutions.uniqueBankUnionsBranches}
                   + ${profileSummary.financialInstitutions.uniqueCreditUnionsBranches}`,
                  top: profileSummary.population
                    .totalUnderservedTractPopulation,
                },
                valueType: isLoading ? 'loading' : 'formula',
              },
            ]}
          />
        </div>
        <div className={`${s.wrapper__column__right} col-sm-6`}>
          <InfoBlock
            title="CDFI"
            data={[
              {
                name: 'Underserved',
                value:
                  profileSummary.financialInstitutionsReports.cdfiUnderserved,
                valueType: isLoading ? 'loading' : 'value',
              },
            ]}
          />
          <InfoBlock
            title="Area"
            data={[
              {
                name: 'Total',
                value: `${profileSummary.areaStats.area}`,
                valueType: isLoading ? 'loading' : 'value',
              },
              {
                name: 'Land Area',
                value: `${profileSummary.areaStats.landMass}`,
                valueType: isLoading ? 'loading' : 'value',
              },
              {
                name: 'Water Area',
                value: `${profileSummary.areaStats.waterMass}`,
                valueType: isLoading ? 'loading' : 'value',
              },
              {
                name: 'Water area percentage',
                value: `${profileSummary.areaStats.waterAreaRatio}`,
                valueType: isLoading ? 'loading' : 'value',
              },
            ]}
          />
          <InfoBlock
            title=" Benchmark Tract(s) Depository Institutions"
            data={[
              {
                name: `Benchmark Tract${
                  profileSummary.benchmarkTractData.selectedTracts.split(',')
                    .length > 0
                    ? 's'
                    : ''
                }`,
                value:
                  profileSummary.benchmarkTractData.selectedTracts.split(','),
                valueType: isLoading ? 'loading' : 'list',
              },
              {
                name: 'CU Branches',
                value: `${profileSummary.benchmarkTractData.uniqueCreditUnionBranches}`,
                valueType: isLoading ? 'loading' : 'value',
              },
              {
                name: 'Bank Branches',
                value: `${profileSummary.benchmarkTractData.uniqueBankBranches}`,
                valueType: isLoading ? 'loading' : 'value',
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
export default AboutCU;
